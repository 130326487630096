<template>
  <div id="printMe" >
    <b-row class="match-height mt-2" v-if="currentItineraryIsEmpty">
      <b-col         
        lg="12"
        md="12"
      > 
        <div  class="mt-4">
          <b-card  title="Aucun itinéraire" header-tag="header" body-class="text-center" class="mx-5">

            <b-card-text>
              Configurer l'itinéraire du groupe en ajoutant chaque journée clé de votre groupe.
            </b-card-text>
         
            <b-button v-if="canEdit"  variant="primary" @click="showAddItineraryDay()">Commencer</b-button>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!currentItineraryIsEmpty">
      <b-col         
        lg="10"
        md="10"
        class="mt-2"
      > 
        <div class="mt-2 mb-2" >
          <h3 class="float-left">Itinéraire du groupe</h3>
          <b-button  class="float-right ml-1 no-print" variant="info" @click="print">Imprimer</b-button>

          <b-button variant="primary"  v-if="canEdit" class="float-right no-print "  @click="showAddItineraryDay()" > Ajouter </b-button>

        </div>

      </b-col>
      <b-col         
        lg="10"
        md="10"
        class="mt-2"
        
      > 
        <b-card >
          <app-timeline >

            <!-- 12 INVOICES HAVE BEEN PAID -->
        
            <app-timeline-item   v-for="item,index in sortedCurrentItinerary" :key="item.id">
              <b-row>
                <b-col>
                  <h4>{{item.title}}</h4>
                  <small>{{item.date}}</small>
                </b-col>
                <b-col>
                    <b-button
                      v-if="canEdit"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      class="no-print"
                      variant="outline-primary"
                      style="float:right"
                      @click="showEditItineraryDay(item.id)"
                    >
                      Modifier
                    </b-button>

                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="5">
                  <p>{{item.description}}</p>
                  <br>
                <b-row v-if="item.boarding_date || item.landing_date">
                <b-col
                  cols="12"
                  class="mb-1"
                >
                <h1 class="section-label mx-0 mt-2">
                  Information pour croisière
                </h1>
                </b-col>
                </b-row>
                <b-row class="" v-if="item.boarding_date || item.landing_date">
                  <b-col cols="6" >
                    <h5 class="mb-0" >
                      Heure d'arrivée
                    </h5>
                    <small>{{item.landing_date}}</small>
                  </b-col>
                  <b-col cols="6">
                    <h5 class="mb-0" >
                      Heure départ
                    </h5>
                    <small>{{item.boarding_date}}</small>
                  </b-col>
                </b-row>
                <b-row class="mt-1" v-if="item.port_of_call">
                  <b-col cols="12"  >
                    <h5 class="mb-0" >
                      Port
                    </h5>
                    <small>{{item.port_of_call}}</small>
                  </b-col>
                </b-row>


                </b-col>
                <b-col cols="3">
                  <div class="mb-3">
                      <b-media
                        no-body
                        class="mb-1"

                      >
                      <b-media-aside class="mr-1">
                          <b-avatar
                            variant="light-warning"
                            size="34"
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="18"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body style="margin:auto">
                          <h5 class="mb-0" >
                            Jour de voyage
                          </h5>
                          <small>#{{item.itinerary_day}}</small>
                        </b-media-body>
                      </b-media>
                      <b-media
                        
                        no-body
                        class="mb-1"
                      >
                      
                        <b-media-aside class="mr-1 ">
                          <b-avatar
                            variant="light-danger"
                            size="34"
                          >
                            <feather-icon
                              icon="UserIcon"
                              size="18"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body style="margin:auto">
                          <h5 class="mb-0" >
                            Repas
                          </h5>
                          <div v-if="item.breakfast || item.dinner || item.supper ">
                            <small v-if="item.breakfast">Déjeuner, </small><small v-if="item.dinner">Diner, </small><small v-if="item.supper"> Souper </small>
                          </div>
                          <div v-else>
                            <small>Aucun</small>
                          </div>                   
                        </b-media-body>
                      </b-media>
                      <b-media
                        v-if="item.mileage"
                        no-body
                        class="mb-1"

                      >
                      <b-media-aside class="mr-1">
                          <b-avatar
                            variant="light-info"
                            size="34"
                          >
                            <feather-icon
                              icon="ActivityIcon"
                              size="18"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body style="margin:auto">
                          <h5 class="mb-0" >
                            Kilométrage
                          </h5>
                          <small v-if="item.mileage && item.mileage != '0'">{{item.mileage}} km</small>
                          <small v-else>Aucun</small>
                        </b-media-body>
                      </b-media>
                      <b-media
                        v-if="item.cruise_day_type"
                        no-body
                        class="mb-1"

                      >
                      <b-media-aside class="mr-1">
                          <b-avatar
                            variant="light-info"
                            size="34"
                          >
                            <feather-icon
                              icon="ActivityIcon"
                              size="18"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body style="margin:auto">
                          <h5 class="mb-0" >
                            Type de journée
                          </h5>
                          <small >{{getCruiseDayTypeLabel(item.cruise_day_type)}}</small>
                        </b-media-body>
                      </b-media>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div v-if="item.image">
                  <b-img

                    :src="item.image"
                    fluid
                    class="mr-1"
                  />
                  </div>
                  <div v-else-if="item.itinerary_day < 22">
                  <b-img
                    :src="getPlaceHolderImg(item.itinerary_day)"
                    fluid
                    class="mr-1"
                  />
                  </div>
                </b-col>
              </b-row>
              <group-itinerary-edit-modal :group-type="currentGroup.type" :ref="`edit-itinerary-day-${item.id}`" :itinerary-day="item" @deleteItineraryDay="deleteCurrentItineraryDay($event, index)" @updateItineraryDay="editItineraryDay($event, index)"/>

            </app-timeline-item>

          </app-timeline>

        </b-card>

      </b-col>

    </b-row>
    <GroupItineraryAddModal :ref="`add-itinerary-day`" :group-type="currentGroup.type" :itinerary-day="{}" @submitItineraryDay="submitItineraryDay"/>

  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BAvatar, BImg,BMedia, BMediaAside, BMediaBody,BCarousel,BCarouselSlide,BBadge,BListGroup, BListGroupItem} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive';
import GroupItineraryEditModal from './GroupItineraryEditModal.vue'
import GroupItineraryAddModal from './GroupItineraryAddModal.vue'
import {computed,onMounted, reactive, watch, ref} from "@vue/composition-api";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import Vue from 'vue'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useLocalisation} from "@/shared/composables/use-localisation";

import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
    import itineraryConfig from '@core/data/itineraryConfig.js'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    AppTimelineItem,
    AppTimeline,
    BAvatar,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCarousel,
    BCarouselSlide,
    BBadge,
    BListGroup,
    BListGroupItem,
    GroupItineraryEditModal,
    GroupItineraryAddModal
  },
  props:['currentItinerary','currentGroup', 'groupId'],
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup(props, {emit}) {
    const {uploadImage, addItineraryDayToGroup, updateItineraryDay, deleteItineraryDay} = useGroupApi();
    const {refOf, forceUpdate} = useApplicationContext();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {t} = useLocalisation();

    const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.role;
    const canEdit = ref(Roles.roleIsAllowed(CURRENT_ROLE?.role || Roles.GUEST.role, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]));


    const submitItineraryDay = async(day) => {
      let object = {
          "breakfast": day.breakfast,
          "itinerary_date": day.itinerary_date,
          "description": day.description,
          "dinner": day.dinner,
          "image": day.image,
          "itinerary_day": day.itinerary_day,
          "mileage": day.mileage,
          "supper": day.supper,
          "title": day.title,
          "landing_date": day.landing_date,
          "boarding_date": day.boarding_date,
          "port_of_call": day.port_of_call,
          "cruise_day_type": day.cruise_day_type

      }

      try {
        let response = await addItineraryDayToGroup(props.groupId, object);
        let match  = (response.headers.location).split( "/" )
        let itinerary_id = match[7];
        object.itinerary_date = day.itinerary_date
        object.id = itinerary_id;
        props.currentItinerary.push(object)
        displaySuccessMessage('Les informations avancées du groupe ont été sauvegardées avec succès');
        emit('monica-group-saved');
      } catch (e) {
        console.error(e)
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }

    }

    const editItineraryDay = async(day, index) => {

      try {
        await updateItineraryDay(props.groupId, day.id, day);
        props.currentItinerary.splice(index, 1)
        props.currentItinerary.push(day)

        forceUpdate();
        displaySuccessMessage('Les informations avancées du groupe ont été sauvegardées avec succès');
      } catch (e) {
        console.error(e)
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }

    }

    const deleteCurrentItineraryDay = async(day,index) => {
      try {
        await deleteItineraryDay(props.groupId, day.id, day);
        props.currentItinerary.splice(index, 1)

        forceUpdate();
        displaySuccessMessage('La journée a été supprimée');
      } catch (e) {
        console.error(e)
        displayErrorMessage("La sauvegarde d'information du groupe a échoué");
      }
    }

    const showEditItineraryDay = (day) => {
      refOf(`edit-itinerary-day-${day}`)[0].$children[0].show();
    };

    const showAddItineraryDay = (day) => {
      refOf(`add-itinerary-day`).$children[0].show();
    } 

    const getCruiseDayTypeLabel = (value) => {
      let object = itineraryConfig.DAY_TYPES.find(item => {
        return item.value === value
      })

      return object.text;
    }

    const getPlaceHolderImg = (index) => {
    
     let img = require(`@/assets/images/itinerary_days/${index}.jpg`); 
     return img
    }

    return {
      canEdit,
      getPlaceHolderImg,
      submitItineraryDay,
      showEditItineraryDay,
      showAddItineraryDay,
      editItineraryDay,
      deleteCurrentItineraryDay,
      t,
      getCruiseDayTypeLabel
      
    }
  },
  methods: {

    async print () {

      const prtHtml = document.getElementById('printMe').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      stylesHtml+=`<link rel="stylesheet" href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'>`
      stylesHtml+=`<link rel="stylesheet" href="https://unpkg.com/kidlat-css/css/kidlat.css">`
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }
      
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
          .no-print {display: none !important;}

          </style>

          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();

    }
  },
  computed: {
    sortedCurrentItinerary() {
      return this.currentItinerary.sort((a, b) => (parseInt(a.itinerary_day) > parseInt(b.itinerary_day)) ? 1 : -1)
    },
    currentItineraryIsEmpty() {
      let isEmpty = true;
      if (this.currentItinerary.length > 0) {
        isEmpty = false
      }
      return isEmpty
    }
  }
}
</script>

<style>
  
</style>
