<template>
  <div>
    <b-card>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
            />
            <b-button
              v-if="canEdit"
              variant="primary"
              class="ml-1"
              :to="{ name: 'group-add-package' }"
            >
              <span class="text-nowrap">Créer un forfait</span>
            </b-button>
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="packages"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Order Id -->
          <span class="small" v-if="props.column.field === 'order_id'">
            <b-button
              variant="link"
              size="sm"
              @click="movePackageUp(props.row.id)"
              :disabled="props.row.originalIndex === 0"
            >
              <feather-icon size="18" icon="ArrowUpIcon" />
            </b-button>
            <b-button
              variant="link"
              size="sm"
              @click="movePackageDown(props.row.id)"
              :disabled="props.row.originalIndex === packages.length - 1"
            >
              <feather-icon size="18" icon="ArrowDownIcon" />
            </b-button>
          </span>

          <!-- Column: Name -->
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            <router-link
              :to="{
                name: 'group-edit-package',
                params: { package_id: props.row.id, id: groupId },
              }"
              class="text-nowrap"
              style="cursor: pointer"
              tag="span"
            >
              <span class="text-nowrap">{{ props.row.name }}</span>
            </router-link>
          </span>

          <!--Column: is_public -->
          <span
            v-else-if="props.column.field === 'is_public'"
            class="text-nowrap text-center mx-auto"
          >
            <span v-if="props.row.is_public">
              <feather-icon
                v-b-tooltip.hover.top.html="
                  'Ce forfait est visible pour les clients externes'
                "
                size="18"
                icon="EyeIcon"
                class="text-success"
              />
            </span>
            <span v-else>
              <feather-icon
                v-b-tooltip.hover.top.html="
                  'Ce forfait ne sera pas affiché pour les clients externes'
                "
                size="18"
                icon="EyeIcon"
                class="text-grey"
              />
            </span>
          </span>

          <!-- Column: status -->
          <span v-else-if="props.column.field === 'status'">
            <!--
          <b-badge :variant="statusVariant(props.row.status)">
            {{ statusLabel(props.row.status) }}
          </b-badge>
          -->
            <b-badge :variant="statusVariant('draft')">
              {{ statusLabel("draft") }}
            </b-badge>
          </span>

          <!-- Column: type -->
          <span v-else-if="props.column.field === 'type'">
            <feather-icon
              :icon="typeIcons(props.row.type)"
              size="18"
              class="mr-50"
              :class="`text-${typeVariant(props.row.type)}`"
            />
            <span class="align-text-top text-capitalize">{{
              typeLabel(props.row.type)
            }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="canEdit"
                  :to="{
                    name: 'group-edit-package',
                    params: { package_id: props.row.id, id: groupId },
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="canEdit"
                  @click="deletePackage(groupId, props.row.id)"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Indiquer 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ref } from "@vue/composition-api";

import { GroupPackagesService } from "../../services/GroupPackagesService.js";
export const groupPackagesService = new GroupPackagesService();

import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    groupId: { type: String },
  },
  setup(props) {
    const CURRENT_ROLE =
      store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]
        .role;
    const publicUrlEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.public_url_enable;
    const canEdit = ref(
      Roles.roleIsAllowed(CURRENT_ROLE.role, [
        Roles.ORGANIZATION_ADMIN.role,
        Roles.ORGANIZATION_COORDINATOR.role,
      ])
    );
    const statusOptions = [
      { label: "completed", value: "completed" },
      { label: "draft", value: "draft" },
    ];

    const packages = ref([]);

    const getPackages = () => {
      return new Promise((resolve, reject) => {
        groupPackagesService
          .getPackages(props.groupId)
          .then((response) => {
            packages.value = response.data;
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const deletePackage = (groupId, packageId) => {
      return new Promise((resolve, reject) => {
        groupPackagesService
          .deletePackage(groupId, packageId)
          .then((response) => {
            packages.value = packages.value.filter(function (pkg) {
              return pkg.id !== packageId;
            });
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const reorderPackages = (reorderedPackageIds) => {
      for (let i = 0; i < reorderedPackageIds.length; i++) {
        const packageId = reorderedPackageIds[i];

        let groupPackage = packages.value.find((x) => x.id === packageId);
        groupPackage.order_id = i;
      }

      packages.value.sort((a, b) => a.order_id - b.order_id);

      groupPackagesService.getPackages(props.groupId).then((response) => {
        store.commit("app-groups/setPackages", response.data);
      });
    };

    const movePackageUp = async (packageId) => {
      let packageIds = packages.value.map((x) => x.id);

      const index = packageIds.findIndex((x) => x === packageId);
      if (index === -1) {
        return;
      }

      packageIds.splice(index, 1);
      packageIds.splice(index - 1, 0, packageId);

      await groupPackagesService.syncPackageOrdering(props.groupId, packageIds);

      reorderPackages(packageIds);
    };

    const movePackageDown = async (packageId) => {
      let packageIds = packages.value.map((x) => x.id);

      const index = packageIds.findIndex((x) => x === packageId);
      if (index === -1) {
        return;
      }

      packageIds.splice(index, 1);
      packageIds.splice(index + 1, 0, packageId);

      await groupPackagesService.syncPackageOrdering(props.groupId, packageIds);

      reorderPackages(packageIds);
    };

    getPackages();

    return {
      statusOptions,
      packages,
      deletePackage,
      canEdit,
      movePackageUp,
      movePackageDown,
      publicUrlEnabled,
    };
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      searchTerm: "",
      typeOptions: ["TRANSPORT", "all_inclusive"],
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      planOptions: [],
    };
  },
  computed: {
    columns() {
      let columns = [
          {
            label: "Ordre d'affichage",
            field: "order_id",
          },
          {
            label: "Nom du forfait",
            field: "name",
          }
        ];

        if (this.publicUrlEnabled) {
          columns.push({
              label: "Affichage du forfait",
              field: "is_public",
              thClass: "text-center",
              tdClass: "text-center",
            })
        }

        columns.push({
          label: "Action",
          field: "action",
        });

        return columns;
    },
    statusVariant() {
      const statusColor = {
        draft: "light-info",
        completed: "light-success",
      };
      return (status) => statusColor[status];
    },

    statusLabel() {
      const statusLabel = {
        draft: "Brouillon",
        completed: "Completé",
      };
      return (status) => statusLabel[status];
    },

    typeVariant() {
      const typeColor = {
        TRANSPORT: "danger",
        all_inclusive: "success",
      };
      return (type) => typeColor[type];
    },

    typeLabel() {
      const typeLabel = {
        TRANSPORT: "Transport",
        all_inclusive: "All Inclusive",
      };
      return (type) => typeLabel[type];
    },

    typeIcons() {
      const typeIcons = {
        TRANSPORT: "DropletIcon",
        all_inclusive: "SunIcon",
      };
      return (type) => typeIcons[type];
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return (character) =>
        Math.round(parseInt(character.slice(-1), UUID_BASE) / BASE);
    },
    avatarColors() {
      const avatarColors = {
        1: "primary",
        2: "dark",
        3: "secondary",
        4: "success",
        5: "danger",
        6: "warning",
        7: "info",
        8: "secondary",
      };
      return (character) => avatarColors[character];
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
